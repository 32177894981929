/*
Desktop Navigation Bar
 */
.navigationBar {
    display: flex;
    flex-direction: row;
    background: rgb(100, 164, 70);
    font-size: 1.5em;
    margin: 0;
    color: rgb(33, 33, 33);
    border-bottom: solid 0.6vh rgb(26, 105, 66);
    justify-content: space-between;
    font-family: 'Trispace', sans-serif;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
    height: 8.8vh;
}

.logoContainer {
    filter: drop-shadow(0px 0px 8px #222);
    -webkit-filter: drop-shadow(0px 0px 8px #222);
}

.logo {
    height: 8vh;
    width: auto;
    margin-left: 60px;
    cursor: pointer;
    -webkit-filter: drop-shadow(0px 0px 8px #222);
    filter: drop-shadow(0px 0px 8px #222);
}

.navigationLinks {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}

.navigationLink {
    font-weight: bold;
    margin: 10px 30px 10px 10px;
    cursor: pointer;
    color: rgb(33, 33, 33);
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
}

.navigationLink:hover {
    -webkit-transition: text-shadow 300ms ease;
    -moz-transition: text-shadow 300ms ease;
    -o-transition: text-shadow 300ms ease;
    color: rgb(169, 204, 141);
    text-shadow: -1.5px 1.5px 0 rgb(26, 105, 66),
    1.5px 1.5px 0 rgb(26, 105, 66),
    1.5px -1.5px 0 rgb(26, 105, 66),
    -1.5px -1.5px 0 rgb(26, 105, 66);
}

/*
Mobile Navigation Bar (Hamburger Menu)
 */

.backgroundBlur {
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    backdrop-filter: blur(4px);
    position: absolute;
    background: RGBA(255, 255, 255, 0.1);
    display: none;
}

.hamburgerIcon {
    width: 30px;
    align-self: center;
    position: absolute;
    right: 20px;
    display: none;
}

.hamburgerMenu {
    z-index: 3;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.mobileNavigationBar {
    display: flex;
    flex-direction: column;
    z-index: 4;
    position: absolute;
    right: 16%;
    top: 13%;
    background: RGBA(175, 175, 175, 0.5);
    border-radius: 100%;
    box-shadow: 0 0 5px 10px RGBA(175, 175, 175, 0.5);
    width: 70%;
    justify-content: center;
    align-items: center;
}

.mobileNavigationLink {
    composes: navigationLink;
    padding: 14px 10px 10px 10px;
    margin: 0;
    font-size: 25px;
    width: fit-content;
    word-wrap: normal;
}

.mobileNavigationBarCloseButton {
    z-index: 5;
    position: absolute;
    right: 0;
    top: 0;
    margin: 30px 34px 0 0;
    font-weight: bold;
    font-size: 2rem;
    font-family: Arial, serif;
    cursor: pointer;
}

/**
Media queries
 */

@media only screen and (max-width: 1230px) {
    .navigationLink {
        font-size: 2vw;
        margin: 10px 20px;
    }

    .logo {
        margin-left: 4.5vw;
    }

    .navigationLinks {
        width: 80vw;
    }
}

@media only screen and (max-width: 975px) {
    .navigationLink {
        font-size: 2.5vw;
        margin: 10px
    }
}

@media only screen and (max-width: 620px) {
    .navigationLinks {
        display: none;
    }

    .hamburgerIcon {
        display: block;
    }

    .mobileNavigationLink {
        margin: 0;
    }
}

@media only screen and (max-width: 440px) {
    .hamburgerMenu > img {
        width: 90vw;
    }

    .mobileNavigationBarCloseButton {
        margin: 6vw 7vw 0 0;
        font-size: 8vw;
    }

    .mobileNavigationBar {
        right: 16vw;
        top: 13vw;
    }

    .mobileNavigationLink {
        font-size: 5.5vw;
        padding: 3.5vw 2vw 2vw 2vw;
    }
}