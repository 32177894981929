body {
    margin: 0;
    padding: 0;
    background: rgb(33, 33, 33);
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

@font-face {
    font-family: Quantum;
    src: local(Quantum), url(./assets/fonts/Quantum.ttf) format('truetype');
}

::-moz-selection { /* Code for Firefox */
    color: darkseagreen;
    background: rgb(26, 105, 66);
}

::selection {
    color: darkseagreen;
    background: rgb(26, 105, 66);
}