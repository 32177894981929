.sponsorsList {
    margin: 10px;
}

.logoSection {
    display: inline-flex;
    margin: 0 auto;
    flex-wrap: wrap;
}

.sponsorsLogo {
    display: block;
    height: 15vh;
    width: auto;
    border-radius: 5px;
    border: 5px solid RGB(169, 204, 141);
    background: RGB(169, 204, 141);
    margin: 5px;
}

@media only screen and (max-width: 420px) {
    .sponsorsLogo {
        width: 75vw;
        height: auto
    }
}