.radioTitle {
    color: #212121;
}

.radioChoiceSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1rem 0;
}

.radioChoice {
    display: flex;
    flex-direction: column;
}

.radioOption {
    color: #212121;
    margin: 0.3rem 0;
    font-weight: normal;
}

.dropDown {
    composes: inputBox from '../Form.module.css';
    color: #212121;
    margin: 0.3rem 0;
    font-weight: normal;
}

@media only screen and (max-width: 420px) {
    .radioChoiceSection {
        flex-direction: column;
    }
}