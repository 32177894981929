.sponsorsPage {
    width: 60vw;
    background: rgba(100, 164, 70, 0.75);
    border-radius: 10px;
    border: 10px solid rgba(26, 105, 66, 0.9);
    font-family: 'Trispace', sans-serif;
    display: flex;
    font-weight: bold;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    color: #212121;
    justify-content: center;
}

.sponsorInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 10px 10px 10px;
    width: available;
}

.navigationBar {
    border-radius: 15px;
    background-color: rgb(26, 105, 66);
    font-family: inherit;
    font-weight: inherit;
    font-size: 1.5em;
    height: fit-content;
    border: 2px solid RGB(169, 204, 141);;
    padding: 0.6rem;
    cursor: pointer;
    margin: 20px 10px;
    text-decoration: none;
    color: RGB(169, 204, 141);
    width: available;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    alignment: center;
    justify-content: space-around;
}

.navigationLink {
    word-wrap: normal;
    text-decoration: none;
    color: #212121;
    margin: 10px 5px;
}

.navigationLink:hover {
    text-decoration: underline;
}

.separator {
    margin: 10px 5px;
    border-right: solid 4px RGB(169, 204, 141);
}

@media only screen and (max-width: 945px) {
    .sponsorsPage {
        min-width: 90vw;
    }
}

@media only screen and (max-width: 945px) {
    .navigationLink {
        font-size: 3.5vw;
    }
}