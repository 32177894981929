.footer {
    background: rgb(100, 164, 70);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    font-weight: bold;
    color: rgb(33, 33, 33);
    font-family: 'Trispace', sans-serif;
    min-height: 11.5vh;
    width: 100%;
    padding: 1.5vh 0 1.5vh 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: solid 0.6vh rgb(26, 105, 66);
}

.socialLinks {
    display: inline-block;
    width: fit-content;
    text-align: center;
}

.icon {
    cursor: pointer;
}

.icon:hover {
    filter: brightness(0) saturate(100%) invert(28%) sepia(86%) saturate(366%) hue-rotate(98deg) brightness(96%) contrast(93%);
}

.instagramIcon {
    composes: icon;
    width: 6vh;
    margin: 0;
    padding: 0;
}

.discordIcon {
    composes: icon;
    width: 7vh;
    margin: 0;
    padding: 0;
}

.githubIcon {
    composes: icon;
    width: 6vh;
    margin: 0;
    padding: 0;
}

.facebookIcon {
    composes: icon;
    width: 5.5vh;
    margin: 0;
    padding: 0;
}

.printablesIcon {
    composes: icon;
    width: 6.5vh;
    margin: 0;
    padding: 0;
}

.mailIcon {
    composes: icon;
    margin: 0;
    padding: 0;
    width: 6.5vh;
}

.copyrightNotice {
    margin: 0 10px;
    padding: 0;
    text-align: center;
}

.socialLink {
    padding: 0;
    margin: 0 3vw;
    color: transparent;
}